/*
  For updating from four day weeks to five day weeks, the following variables and functions are important:
    MIN_WEEK_LENGTH
    MIN_FOR_DAY_WEEK_DATE

    isWorkDay (func)
    monthWeekDates (func)
 */
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import {
  find, findIndex, groupBy, includes, last, sortBy,
} from 'lodash';
import { IRouterStep } from '../../pages/ProductionSchedule/types';
import theme from '../theme';
import { COMPLETE_STEPS, FINISHING_STEPS } from './index';
import { IClientRunner, IRunnerHistory } from '../../pages/Orders/types';

export const START_DATE = new Date(2021, 0, 1);
export const END_DATE = new Date(new Date().getFullYear(), 11, 31);
const MIN_WEEK_LENGTH = 4;
// const MIN_WEEK_LENGTH = localStorage.getItem('weekLength') === '5' ? 4 : 3;
// const MIN_FOUR_DAY_WEEK_DATE = localStorage.getItem('weekLength') === '5' ? new Date(2023, 5, 19) : new Date(2023, 1, 1);
export const SALES_ORDER_START_KEY = 'salesOrderStartDate';
export const SALES_ORDER_END_KEY = 'salesOrderEndDate';

export const ORDERS_VIEW_START_KEY = 'orderViewStartDate';
export const ORDERS_VIEW_END_KEY = 'orderViewEndDate';
export const NC_RECORD_START_KEY = 'ncRecordsStartDate';
export const NC_RECORD_END_KEY = 'ncRecordsEndDate';
export const SHIPMENTS_VIEW_START_KEY = 'shipmentsViewStartDate';
export const SHIPMENTS_VIEW_END_KEY = 'shipmentsViewEndDate';
export const HOLIDAYS = [
  '1/1/2021',
  '5/31/2021',
  '7/5/2021',
  '9/6/2021',
  '11/25/2021',
  '12/25/2021',
  '12/27/2021',
  '12/28/2021',
  '12/29/2021',
  '12/30/2021',
  '12/31/2021',
  '1/1/2022',
  '5/30/2022',
  '7/4/2022',
  '9/5/2022',
  '11/24/2022',
  '12/26/2022',
  '12/27/2022',
  '12/28/2022',
  '12/29/2022',
  '12/30/2022',
  '12/31/2022',
  // CLOSED FOR NEW YEAR'S DAY
  '1/1/2023',
  '1/2/2023',
  // CLOSED FOR MEMORIAL DAY
  '5/29/2023',
  // CLOSED FOR JULY 4TH
  '7/4/2023',
  // CLOSED FOR LABOR DAY
  '9/4/2023',
  // CLOSED FOR THANKSGIVING
  '11/23/2023',
  // CLOSED FOR CHRISTMAS
  '12/25/2023',
  // '12/26/2023',
  // '12/27/2023',
  // '12/28/2023',
  // '12/29/2023',
  '1/1/2024',
  // CLOSED FOR MEMORIAL DAY
  '5/27/2024',
  // CLOSED FOR JULY 4TH
  '7/4/2024',
  '7/5/2024',
  // CLOSED FOR LABOR DAY
  '9/2/2024',
  // CLOSED FOR THANKSGIVING
  '11/28/2024',
  // CLOSED FOR CHRISTMAS
  '12/25/2024',
  '1/1/2025',
  // CLOSED FOR MEMORIAL DAY
  '5/26/2025',
  // CLOSED FOR JULY 4TH
  '7/4/2025',
  // CLOSED FOR LABOR DAY
  '9/1/2025',
  // CLOSED FOR THANKSGIVING
  '11/27/2025',
  // CLOSED FOR CHRISTMAS
  '12/25/2025',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const invalidOrderDate = (current: any) => {
  const holidayMoments = HOLIDAYS.filter((d) => moment(d).isSame(current));
  const isWeekend = [0, 6].indexOf(moment(current).weekday()) > -1;
  return holidayMoments.length > 0 || isWeekend;
};

export const invalidTimeMachineDate = (current: any) => moment(current) > moment(new Date());

export const fileDateStamp = (d: Date|null = null) => {
  const date = d || new Date();
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const monthDate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  return `${date.getFullYear()}${month}${monthDate}`;
};

export const dateString = (d: Date, noYear = false, truncateYear = false) => {
  if (!d) return '';
  if (noYear) return `${d.getMonth() + 1}/${d.getDate()}`;
  if (truncateYear) return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().slice(2)}`;
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
};

export const dateBefore = (a: Date, b: Date) => {
  // tests to see if date "a" is before date "b"
  // significant to seconds
  if ((!a && b) || (a && !b)) return false;
  if (a.getFullYear() > b.getFullYear()) return false;
  if (a.getMonth() < b.getMonth()) return true;
  if (a.getMonth() === b.getMonth()) {
    if (a.getDate() < b.getDate()) return true;
    if (a.getDate() === b.getDate() && a.getSeconds() < b.getSeconds()) return true;
  }
  return false;
};

export const stringToDate = (d: string) => {
  const dateParts = d.split('/');
  const [month, date] = dateParts.map((p) => parseInt(p, 10));
  let year = dateParts.length === 3 ? parseInt(dateParts[2], 10) : null;

  if (!year) year = new Date().getFullYear();

  return new Date(year, month - 1, date);
};

/*
  deprecated -- was for toggling between four- and five-day weeks
*/
// export const isWorkDay = (d: Date) => {
//   const validDays = [1, 2, 3, 4, 5];
//   if (d.getTime() < MIN_FOUR_DAY_WEEK_DATE.getTime()) validDays.push(5);
//   return includes(validDays, d.getDay());
// };
export const isHoliday = (d: Date) => includes(HOLIDAYS, dateString(d));
export const isWorkDay = (d: Date) => includes([1, 2, 3, 4, 5], d.getDay());

export const closestWorkDay = (d: Date) => {
  if (d.getDay() === 0) d.setDate(d.getDate() + 1);
  if (d.getDay() === 6) d.setDate(d.getDate() + 2);
  return d;
};

export const closestMonday = (date: Date = new Date()): Date => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
};

export const getWeek = (targetDate: Date = new Date(), startIndex: number = 1, weekDays: Date[] = [], includeWeekends: boolean = false) => {
  if ((weekDays.length === 5 && !includeWeekends) || (weekDays.length === 7 && includeWeekends)) return weekDays;

  const weekDay = targetDate.getDate() - targetDate.getDay() + startIndex;
  const newDate = new Date(targetDate.setDate(weekDay));
  newDate.setHours(0, 0, 0, 0);

  return getWeek(targetDate, startIndex + 1, [...weekDays, newDate], includeWeekends);
};

export const workDatesBetween = (start: Date = START_DATE, end: Date = END_DATE, calendarDates: Date[] = []): Date[] => {
  if (start > end) return calendarDates;
  const d = new Date(start);
  start.setDate(start.getDate() + 1);

  if (isWorkDay(d)) {
    return workDatesBetween(start, end, [...calendarDates, d]);
  }
  return workDatesBetween(start, end, calendarDates);
};

export const weekStartDate = (orderWeeks: any) => {
  const today = new Date();
  if (today.getDay() === 0) { // if it's Sunday, make it Monday
    today.setDate(today.getDate() + 1);
  } else if (today.getDay() === 6) { // if it's Saturday, make it Friday
    today.setDate(today.getDate() - 1);
  }
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();

  const todaysDate = `${yyyy}-${mm}-${dd}`;

  let foundArray = null;

  orderWeeks.forEach((level1Array) => {
    level1Array.forEach((level2Array) => {
      level2Array.forEach((dateStr) => {
        const dateElem = new Date(dateStr);
        const newDateString = dateElem.toISOString().split('T')[0];
        if (newDateString === todaysDate) {
          foundArray = level2Array;
        }
      });
    });
  });

  return foundArray ? foundArray[0] : foundArray;
};

export const workingDaysFrom = (
  fromDate: Date,
  workingDays = 1,
  routerType = 'body',
  toppedOrBound = false,
  needsFinishing = true,
  inFinishing = false,
) => {
  // ensure that the argument is a valid and past router
  let daysRemaining = workingDays;
  if (toppedOrBound && !inFinishing) daysRemaining += 1;
  if (!needsFinishing) {
    const subDays = routerType === 'body' ? 2 : 3;
    daysRemaining -= subDays;
  }

  while (daysRemaining > 0) {
    fromDate.setDate(fromDate.getDate() + 1);
    const day = fromDate.getDay();
    // if (!includes([0, 6], day)) {
    if (isWorkDay(fromDate)) {
      daysRemaining -= 1;
    }
  }
  return fromDate;
};

export const workingDaysBefore = (
  beforeDate: Date,
  workingDays = 1,
  routerType = 'body',
  toppedOrBound = false,
  needsFinishing = true,
  inFinishing = false,
  ihsNeeded = false,
  outsideFinishRequired = false,
) => {
  // ensure that the argument is a valid and past router
  let daysRemaining = workingDays;
  // If IHS NEEDED
  // if (ihsNeeded) {
  //   if (routerType === 'neck') daysRemaining += 5;
  //   else if (toppedOrBound && routerType === 'body') daysRemaining += 2;
  //   else daysRemaining += 1;
  // }
  // Block and bound
  // Topped bodies
  if (toppedOrBound && !inFinishing) {
    if (routerType === 'neck') daysRemaining += 4;
    else daysRemaining += 2;
  }

  if (!needsFinishing) {
    const subDays = routerType === 'body' ? 2 : 3;
    daysRemaining -= subDays;
  }

  if (outsideFinishRequired) {
    daysRemaining += 7;
  }

  while (daysRemaining > 0) {
    beforeDate.setDate(beforeDate.getDate() - 1);
    if (isWorkDay(beforeDate) && !isHoliday(beforeDate)) {
      daysRemaining -= 1;
    }
  }

  return beforeDate;
};

export const daysAtStep = (history: IRunnerHistory, time: Date, excludeWeekends: boolean = false) => {
  /*
   How long has a work order been at its current step?
   subtract when it was checked in from current time, then divide by (hours * min * sec * ms) to get duration
   By default include weekend days (legacy support for exisitng code)
  */
  const today = new Date(time);
  today.setHours(0, 0, 0, 0);

  const checkedIn = new Date(history.dateEntered.toDate());
  checkedIn.setHours(0, 0, 0, 0);

  let dayCount = 0;

  for (let d = new Date(checkedIn); d <= today; d.setDate(d.getDate() + 1)) {
    if (!excludeWeekends || (excludeWeekends && d.getDay() !== 0 && d.getDay() !== 6)) {
      dayCount += 1;
    }
  }

  return dayCount - 1;
};

export const workWeeksForMonth = (month: Date[], workWeeks: Date[][] = []): Date[][] => {
  if (month.length === 0) {
    // drop all holidays from the weeks being returned
    return workWeeks.map((week: Date[]) => week.filter((d: Date) => !isHoliday(d)));
  }

  // If the month is the starting month for four-day weeks or greater && we are in four-day display mode
  // make the minimum week length 4, otherwise 5 (for historical lookup)
  // const lastDayOfWeek = (month[0].getMonth() >= MIN_FOUR_DAY_WEEK_DATE.getMonth() && (localStorage.getItem('weekLength') || null) === '4') ? 4 : 5;
  const lastDayOfWeek = 5;
  const firstDay = month[0].getDay();
  // if the day is friday, return the function with the week closed off to new days
  if (firstDay === 5) {
    return workWeeksForMonth(month.slice(1), [...workWeeks, [month[0]]]);
  }

  // if the day is not the last day of the week, we will take the number of remaining days in the week
  const take = lastDayOfWeek - firstDay + 1;
  return workWeeksForMonth(month.slice(take), [...workWeeks, [...month.slice(0, take)]]);
};

export const compressWeeks = (month: Date[][], workWeeks: Date[][] = []): Date[][] => {
  if (!month.length) {
    return workWeeks;
  }

  const currentWeek = month[0];

  // if we are on the last week of the month, merge into prev week if too short
  if (month.length === 1 && currentWeek.length < MIN_WEEK_LENGTH) {
    const lastWeek = last(workWeeks) || [];
    return compressWeeks(month.slice(1), [...workWeeks.slice(0, workWeeks.length - 1), [...lastWeek, ...currentWeek]]);
    // if we are on the first week of the month, merge into next week if too short
  } if (month.length > 1 && currentWeek.length < MIN_WEEK_LENGTH) {
    const nextWeek = month[1];
    return compressWeeks(month.slice(2), [...workWeeks, [...currentWeek, ...nextWeek]]);
  }

  return compressWeeks(month.slice(1), [...workWeeks, currentWeek]);
};

export const findJobWeek = (weeks: Date[][], jobDate: Date) => findIndex(weeks, (w: Date[]) => {
  const dates = w.map((d) => dateString(d));
  return includes(dates, dateString(jobDate));
});

export const calendarDates = workDatesBetween();
export const monthDates = (dates: Date[] = calendarDates): Date[][] =>
  // const currentYear = new Date().getFullYear();
  // const currentYearDates = dates.filter((d: Date) => d.getFullYear() === currentYear);
  sortBy(Object.values(groupBy(dates, (d: Date) => d.getMonth())), (d: Date[]) => d[0].getFullYear());

export const yearWeeks = (dates: Date[][] = monthDates()) => dates.map((ds) => {
  const month = workWeeksForMonth(ds);
  return compressWeeks(month);
});

export const getOrderWeeks = (startDate: Date, endDate: Date): Date[][][] => {
  const sDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const eDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const dates = workDatesBetween(sDate, eDate);

  const mDates = monthDates(dates);
  return yearWeeks(mDates);
};

export const shipDelta = (routerSteps: IRouterStep[], shipDate: Date, runner: IClientRunner) => {
  const routerStep = find(routerSteps.filter((r: IRouterStep) => r.id === runner.step));

  if (routerStep?.name && routerStep.name.match(/hold/i)) return 100;
  if (routerStep?.name && routerStep.name.match(/qa/i)) return 0;

  const today = new Date();
  const inFinishing = includes([...FINISHING_STEPS, ...COMPLETE_STEPS], runner.step);
  const workingDays = routerStep?.daysToCompletion !== undefined && routerStep?.daysToCompletion !== null ? routerStep.daysToCompletion : 100;
  const predictedShip = workingDaysFrom(today, workingDays, runner.type, runner.toppedOrBound, runner.finishing, inFinishing);
  predictedShip.setHours(0, 0, 0, 0);
  // @ts-ignore
  const shipsInDays = Math.round((predictedShip - shipDate) / 86400000);

  return shipsInDays;
};

export const expectedShipDate = (routerSteps: IRouterStep[], router: IClientRunner): [IRouterStep, Date, number, string] => {
  const step = find(routerSteps, (s: IRouterStep) => s.id === router.step) as IRouterStep;
  const today = new Date();
  const inFinishing = includes([...FINISHING_STEPS, ...COMPLETE_STEPS], router.step);
  const expectedShip = step.name.match(/complete/i) ? router.completedDate || router.shipDate.toDate()
    : workingDaysFrom(today, step?.daysToCompletion, router.type, router.toppedOrBound, router.finishing, inFinishing);
  const shouldShip = router.shipDate?.toDate();
  // @ts-ignore
  // eslint-disable-next-line no-shadow
  const shipDelta = Math.round((shouldShip - expectedShip) / 86400000);

  let bgColor = theme.palette.success[200];
  if (step.name !== 'Complete') {
    if (shipDelta < -2 && shipDelta > -5) {
      bgColor = theme.palette.warning[200];
    } else if (shipDelta <= -5) {
      bgColor = theme.palette.error[200];
    }
  }

  return [step as IRouterStep, expectedShip, shipDelta, bgColor];
};

export const currentWeek = (date: Date = new Date()) => {
  // const today = new Date();
  const currentMonthWeeks = yearWeeks()[date.getMonth()];
  // if we are on the weekend, jump to a work week.
  // if Sunday, move to next Monday (+1 day), if Saturday,
  // go back to Friday (-1 day)
  if (date.getDay() === 0) date.setDate(date.getDate() + 1);
  if (date.getDay() === 6) date.setDate(date.getDate() - 1);

  const todayString = dateString(date);
  const _currentWeek = currentMonthWeeks.filter((week: Date[]) => {
    const weekAsString = week.map((d: Date) => dateString(d));
    return weekAsString.indexOf(todayString) > -1;
  });

  return _currentWeek;
};
export const startOfCurrentWeek = (date: Date = new Date(), returnDate: boolean = false) => {
  const _currentWeek = currentWeek(date);
  if (!_currentWeek.length) {
    if (returnDate) return closestMonday();
    return closestMonday().getTime().toString();
  }

  if (returnDate) return _currentWeek[0][0];
  return _currentWeek[0][0].getTime();
};

export const isSameDay = (a: Date, b: Date) => {
  const _a = new Date(a);
  const _b = new Date(b);

  [_a, _b].forEach((d) => {
    d.setHours(0, 0, 0);
  });

  return (_a.getDate() === _b.getDate() && _a.getMonth() === _b.getMonth() && _a.getFullYear() === _b.getFullYear());
};

export const firebaseShipDate = (d: Date = new Date()) => {
  // if ship date is a Saturday or Sunday, set the date to the previous Friday so it shows up in our production schedule.
  if (d.getDay() === 0) d.setDate(d.getDate() + (5 - (d.getDay() || 7)));
  d.setHours(0, 0, 0, 0);
  return Timestamp.fromDate(d);
};
export const dateDistance = (a: Date, b: Date) => {
  // @ts-ignore
  const millis = Math.abs(a - b);
  return Math.floor(millis / (1000 * 60 * 60 * 24));
};

export const localTime = (d: Date) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return d.toLocaleTimeString(undefined, options);
};

export const monthByNumber = (n: string) => [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
][parseInt(n, 10)];

export const qboDateString = (date: Date = new Date(), includeTime: boolean = false) => {
  const dateTimeString = moment(date).format();
  if (includeTime) return dateTimeString.substr(0, 19);
  return dateTimeString.substr(0, 10);
};

export const mmddyyDate = (date: Date = new Date()) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
  const year = String(date.getFullYear()).substr(2);
  return `${month}/${day}/${year}`;
};

export const greaterDate = (a: Date, b: Date) => (Math.max(a.getTime(), b.getTime()) === a.getTime() ? a : b);
export const lesserDate = (a: Date, b: Date) => (Math.min(a.getTime(), b.getTime()) === a.getTime() ? a : b);

/**
 * Function lastOfFutureMonth returns the date of the last day of a given number of months in the future.
 *
 * @param {Date} startDate - A date (ideally the current date)
 * @param {number} [monthsInFuture=3] - The number of months in the future, defaults to 3 if not specified.
 *
 * @return {Date} - A new Date object that represents the last day of the desired month in the future.
 */
export const lastOfFutureMonth = (startDate: Date, monthsInFuture: number = 3): Date => {
  const _date = new Date(startDate);
  _date.setMonth(startDate.getMonth() + monthsInFuture);
  _date.setDate(0);
  return _date;
};

export const parseYearMonthTag = (tag: string): { tag: string, year: string, month: string} => {
  const [year, month] = tag.split('.').map((s) => parseInt(s, 10));

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  return { tag, year: year.toString(), month: monthNames[month] };
};

const closestPreviousBusinessDay = (date: Date) => {
  const prevDate = new Date(date.getTime());
  prevDate.setDate(prevDate.getDate() - 1);

  // While the date is a weekend or a holiday, decrement the date
  while (prevDate.getDay() === 6 || prevDate.getDay() === 0 || HOLIDAYS.includes(dateString(prevDate))) {
    prevDate.setDate(prevDate.getDate() - 1);
  }

  return prevDate;
};

export const lastOfPrevWorkWeek = (date: Date): Timestamp => {
  const startOfWorkWeek = new Date(startOfCurrentWeek(date));
  return Timestamp.fromDate(closestPreviousBusinessDay(startOfWorkWeek));
};

export const firstOfNextWorkWeek = (date: Date): Timestamp => {
  const nextDate = new Date(date.getTime());
  nextDate.setDate(nextDate.getDate() + 7 - nextDate.getDay() + (nextDate.getDay() === 0 ? 1 : 0));

  // while day is a weekend or a holiday
  while (nextDate.getDay() === 6 || nextDate.getDay() === 0 || HOLIDAYS.includes(dateString(nextDate))) {
    nextDate.setDate(nextDate.getDate() + 1);
  }

  return Timestamp.fromDate(nextDate);
};

export const daysElapsedInWeek = (week: Date[]) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return findIndex(week, (d) => d.getDate() === today.getDate()) + 1;
};
