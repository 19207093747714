import { resolveFirestorePath } from './helper';

const items = resolveFirestorePath('inventory-items');
const bins = resolveFirestorePath('inventory-bins');
const adjustments = resolveFirestorePath('inventory-adjustments');
const snapshots = resolveFirestorePath('inventory-snapshots');
const receptions = resolveFirestorePath('inventory-receptions');

export default {
  items,
  bins,
  adjustments,
  snapshots,
  receptions,
};
